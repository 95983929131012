<template>
  <div>
        <div class="selectContainer" :style="getSelectContainerStyles(pro.style)">
   <select name="format" id="format" :style="getSelectInputStyles(pro.style)" v-model="product.selectedVariant">
      <!-- <option selected disabled ></option> -->
      <option value="" >{{pro.selectVariantText || 'Select Variant'}}</option>
      <option
                                          v-for="(
                                            variant, varientIndex
                                          ) in product.variants"
                                          :key="varientIndex"
                                          :value="variant"
                                           @click="selectVariant(variant)"
                                        >
                                          {{ variant.title }}
                                        </option>
  
      
      
   </select>
</div>
  </div>
</template>

<script>
export default {
props:['pro','product'],
data(){
   return {
      selectedVariant:''
   }
},

methods:{

   switchSelect(e){
      console.log("Selected Variant" , e)
   },

   getSelectInputStyles(property){
      return `background:${property.productVariantStyles?.background  || '#000'}; color:${property.productVariantStyles?.color  || '#fff'};font-size:${property.productVariantStyles?.fontSize  || 15}px;font-family:${property.productVariantStyles?.fontFamily  || 'Poppins'};`
   },

   getSelectContainerStyles(property){
       return `height:${property.productVariantStyles?.height  || 32}px; background:${property.productVariantStyles?.arrowBackground  || '#000'}; line-height:${property.productVariantStyles?.lineHeight  || 2};border-radius:${property.productVariantStyles?.borderRadius || 4}px; --arrowColor:${property.productVariantStyles?.arrowColor || '#fff'} ; --arrrowBgColor:${property.productVariantStyles?.arrowBackground  || '#000'}`

      //  color:${property.productVariantStyles?.arrowColor  || '#fff'};
   }
}
}
</script>

<style scoped>
    select {
   -webkit-appearance:none;
   -moz-appearance:none;
   -ms-appearance:none;
   appearance:none;
   outline:0;
   box-shadow:none;
   border:0!important;
   background-image: none;
   flex: 1;
   padding: 0 .5em;
   cursor:pointer;
}
select::-ms-expand {
   display: none;
}
.selectContainer {
   position: relative;
   display: flex;
   width: 100%;
   overflow: hidden;
}
.selectContainer::after {
   content: '\25BC';
   position: absolute;
   top: 0;
   right: 0;
   padding: 0 0.8rem;
   background: var(--arrrowBgColor);
   color:var(--arrowColor);
   cursor:pointer;
   pointer-events:none;
   transition:.25s all ease;
}

</style>